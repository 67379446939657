/*global $, jQuery */

class BASearchMap {
    initMap(id) {
        this.searchMap = new mapboxgl.Map({
            container: id,
            pitch: 0,
            zoom: 10,
            center: [11.566006, 48.153079],
            attributionControl: false,
            minZoom: 10,
            maxZoom: 18
        });
        this.searchMap.setMaxBounds([
            [11.0, 47.9],
            [11.93, 48.4]
        ]);
        this.searchMap.setStyle(MAPBOXGL_STYLES);
    }

    addBa(baNo, ba) {
        this.searchMap.addLayer({
            'id': 'munich-ba-' + baNo + '-outlines',
            'type': 'line',
            'source': {
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    "features": [ba]
                }
            },
            'layout': {},
            'paint': {
                'line-color': '#0000ff',
                'line-dasharray': [3, 3],
                'line-width': 1,
                'line-opacity': 0.5
            }
        });

        this.searchMap.addLayer({
            'id': 'munich-ba-' + baNo,
            'type': 'fill',
            'source': {
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    "features": [ba]
                }
            },
            'layout': {},
            'paint': {
                'fill-color': '#ff7800',
                'fill-opacity': 0.0,
            }
        });

        const baLabel = document.getElementById('ba_map_label');

        this.searchMap.on('mousemove', 'munich-ba-' + baNo, (e) => {
            if (e.features.length > 0) {
                this.searchMap.getCanvas().style.cursor = 'pointer';
                this.searchMap.getCanvas().style.fillOpacity = "1";
                baLabel.classList.remove('hidden');
                baLabel.innerText = e.features[0].properties.name;
                this.searchMap.setPaintProperty('munich-ba-' + baNo, 'fill-opacity', 0.5);
            } else {
                this.searchMap.getCanvas().style.cursor = '';
                this.searchMap.getCanvas().style.fillOpacity = "0";
                baLabel.classList.add('hidden');
                baLabel.innerText = '';
                this.searchMap.setPaintProperty('munich-ba-' + baNo, 'fill-opacity', 0);
            }
        });
        this.searchMap.on('mouseleave', 'munich-ba-' + baNo, () => {
            this.searchMap.getCanvas().style.cursor = '';
            this.searchMap.getCanvas().style.fillOpacity = "0";
            baLabel.classList.add('hidden');
            baLabel.innerText = '';
            this.searchMap.setPaintProperty('munich-ba-' + baNo, 'fill-opacity', 0);
        });
        this.searchMap.on('click', 'munich-ba-' + baNo, (e) => {
            if (e.features.length > 0) {
                var newUrl = this.$searchMapEl.data("ba-url");
                newUrl = newUrl.replace(/##BA##/, e.features[0].id);
                newUrl = newUrl.replace(/%23%23BA%23%23/, e.features[0].id);
                window.location.href = newUrl;
            }
        });
    }

    onDataLoaded(geoData) {
        geoData.forEach(ba => {
            // Make sure the ending equals the beginning
            ba.geometry.coordinates.forEach(shape => {
                shape.push(shape[0]);
            });
        });

        var geojsonMucOuter = geoData.shift();
        geojsonMucOuter.geometry.coordinates = [[[11, 47.6], [13, 47.6], [13, 48.5], [11, 48.5], [11, 47.6]], ...geojsonMucOuter.geometry.coordinates];

        this.searchMap.addLayer({
            'id': 'munich-outer-filling',
            'type': 'fill',
            'source': {
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    "features": [geojsonMucOuter]
                }
            },
            'layout': {},
            'paint': {
                'fill-color': '#ffffff',
                'fill-opacity': 0.75
            }
        });
        this.searchMap.addLayer({
            'id': 'munich-outer',
            'type': 'line',
            'source': {
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    "features": [geojsonMucOuter]
                }
            },
            'layout': {},
            'paint': {
                'line-color': '#0000ff',
                'line-width': 1,
                'line-opacity': 0.7
            }
        });

        for (let baNo = 1; baNo <= geoData.length; baNo++) {
            const ba = geoData[baNo - 1];
            this.addBa(baNo, ba);
        }
    }

    constructor(id) {
        this.$searchMapEl = $("#" + id);
        this.initMap(id);
        $.get(this.$searchMapEl.data('geo-src'), this.onDataLoaded.bind(this));
    }
}


class DetailedSidebarSearch {
    constructor($form) {
        this.boundingbox = [[48.248199, 11.72291], [48.06155, 11.36084]];
        this.$mapContainer = $form.find(".search_geo");
        this.$lngFrom = $form.find("input[name=geo_lng_from]");
        this.$lngTo = $form.find("input[name=geo_lng_to]");
        this.$latFrom = $form.find("input[name=geo_lat_from]");
        this.$latTo = $form.find("input[name=geo_lat_to]");

        $form.find('#datum_von, #datum_bis').datetimepicker({
            lang: "de",
            i18n: {
                de: {
                    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
                    dayOfWeek: ["So.", "Mo", "Di", "Mi", "Do", "Fr", "Sa."]
                }
            },
            timepicker: false,
            format: "d.m.Y",
            minDate: "2014-05-01",
            maxDate: 0
        });
    }
}

class StandardMapboxMap {
    htmlEntities(str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    }

    initMap() {
        var location = this.$el.data("lat-lng").split(","),
            title = this.$el.data("title"),
            id = this.$el.attr("id");
        if (!id) {
            id = "mapbox_map_" + Math.floor(Math.random() * 10000000);
            this.$el.attr("id", id);
        }

        const coordinates = [parseFloat(location[1]), parseFloat(location[0])];

        const map = new mapboxgl.Map({
            container: id,
            pitch: 0,
            zoom: 15,
            center: coordinates,
            attributionControl: false,
            minZoom: 12,
            maxZoom: 18
        });
        map.setStyle(MAPBOXGL_STYLES);

        const marker = new mapboxgl.Marker();
        marker.setLngLat(coordinates);
        marker.addTo(map);

        const popup = new mapboxgl.Popup({
            offset: 30,
            closeButton: false,
            closeOnClick: false
        });
        popup.setLngLat(coordinates);
        popup.setHTML("<b>" + this.htmlEntities(title) + "</b>");
        popup.addTo(map);
    }

    constructor($element) {
        this.$el = $element;
        this.initMap();
    }
}

jQuery(function () {
    $(".mapboxgl-map-std").each(function () {
        new StandardMapboxMap($(this));
    });

    let $ruSearchForm = $(".ru_search_form");
    if ($ruSearchForm.length > 0) {
        new DetailedSidebarSearch($ruSearchForm);
    }

    $(".suche_caller").click(function (ev) {
        $('#erweiterte_suche').show();
        new BASearchMap('ba_map_search');
        ev.preventDefault();
    });

    $(document).on("click", "a.scrollintoview", function (ev) {
        var $target = $($(this).attr("href"));

        if ($target.length > 0) {
            ev.preventDefault();
            $target.scrollintoview({top_offset: -100});
        }
    });


    var openAjaxLink = function (url, cb) {
        $.get(url, (data) => {
            $("#main").html(data['html']);
            $("head link[rel=canonical]").attr(data['canonical']);
            $("head title").text(data['title']);
            if (data['robots_index']) {
                $("head meta[name=robots]").attr('index,follow');
            } else {
                $("head meta[name=robots]").attr('noindex,follow');
            }
            if (cb) {
                cb(data);
            }
        });
    };
    $(document).on("click", "a.ajax-link", function (ev) {
        ev.preventDefault();
        var ajaxUrl = $(this).data("ajax");
        openAjaxLink(ajaxUrl, function (data) {
            if (window.history && window.history.pushState) {
                window.history.pushState({ajax_url: ajaxUrl}, 'Rathaus Umschau', data['canonical']);
            }
        });
    });
    $(window).on("popstate", function (ev) {
        if (ev.originalEvent && ev.originalEvent.state && ev.originalEvent.state.ajax_url) {
            openAjaxLink(ev.originalEvent.state.ajax_url);
        }
    });
    if (window.history && window.history.replaceState) {
        window.history.replaceState({ajax_url: $(".ru-overview").data("ajax")}, 'Rathaus Umschau', window.location);
    }

    if (location && location.hash[0] == '#') {
        var $target = $("#ru_" + location.hash.substr(1));
        if ($target.length > 0) {
            $target.scrollintoview({top_offset: -100});
        }
    }

    $(".jssocials-share-link").on("click", function (ev) {
        ev.preventDefault();
        const link = $(this).attr("href");
        window.open(link, null, "width=600, height=400, location=0, menubar=0, resizeable=0, scrollbars=0, status=0, titlebar=0, toolbar=0");
    });
});


const MAPBOXGL_STYLES = {
    "version": 8,
    "name": "mvg-osm",
    "metadata": {
        "mapbox:autocomposite": false,
        "mapbox:type": "template",
        "maputnik:renderer": "mbgljs",
        "mapbox:sdk-support": {
            "js": "0.45.0",
            "android": "6.0.0",
            "ios": "4.0.0"
        },
        "openmaptiles:version": "3.x"
    },
    "sources": {
        "osm i-system": {
            "type": "vector",
            "tiles": [
                "https://map-component.app.swm.de/api/v1/xyz/osm_vector/{z}/{x}/{y}.pbf"
            ],
            "minZoom": 1,
            "maxZoom": 24
        }
    },
    "glyphs": "https://map-component-static.app.swm.de/glyphs/{fontstack}/{range}.pbf",
    "id": "acxwb5ssx",
    "layers": [
        {
            "id": "bounding_box",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "background"
            },
            "source": "osm i-system",
            "source-layer": "bounding_box",
            "minzoom": 1,
            "maxzoom": 24,
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": {
                    "stops": [
                        [
                            11,
                            "#e8e8e8"
                        ],
                        [
                            13,
                            "#f7f7f7"
                        ]
                    ]
                },
                "fill-opacity": 1
            }
        },
        {
            "id": "landmark-residential",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-21-0\n"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 8.298051830019984,
            "maxzoom": 18.796258631351467,
            "filter": [
                "==",
                "landuse",
                "residential"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": {
                    "stops": [
                        [
                            15.3,
                            "#eaeaea"
                        ],
                        [
                            15.4,
                            "#f0f0f0"
                        ]
                    ]
                },
                "fill-opacity": 1
            }
        },
        {
            "id": "landmark-education",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-16-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 11.384638023421234,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "amenity",
                    "university"
                ],
                [
                    "==",
                    "amenity",
                    "college"
                ],
                [
                    "==",
                    "amenity",
                    "school"
                ],
                [
                    "==",
                    "amenity",
                    "kindergarten"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#f0e7bc",
                "fill-opacity": 0.4
            }
        },
        {
            "id": "landmark-greenland",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-6-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "leisure",
                    "park"
                ],
                [
                    "==",
                    "leisure",
                    "playground"
                ],
                [
                    "==",
                    "landuse",
                    "recreation_ground"
                ],
                [
                    "==",
                    "leisure",
                    "recreation_ground"
                ],
                [
                    "==",
                    "leisure",
                    "nature_reserve"
                ],
                [
                    "==",
                    "leisure",
                    "common"
                ],
                [
                    "==",
                    "leisure",
                    "pitch"
                ],
                [
                    "==",
                    "landuse",
                    "grass"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#b6e59e",
                "fill-opacity": 0.8
            }
        },
        {
            "id": "landmark-forest",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-12-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "landuse",
                    "forest"
                ],
                [
                    "==",
                    "leisure",
                    "recreation_ground"
                ],
                [
                    "==",
                    "landuse",
                    "recreation_ground"
                ],
                [
                    "==",
                    "landuse",
                    "conservation"
                ],
                [
                    "==",
                    "natural",
                    "wood"
                ],
                [
                    "==",
                    "landuse",
                    "wood"
                ],
                [
                    "==",
                    "natural",
                    "scrub"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#80ce56",
                "fill-opacity": 0.4
            }
        },
        {
            "id": "landmark-golf",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-8-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 10.384638023421234,
            "maxzoom": 24,
            "filter": [
                "==",
                "leisure",
                "golf_course"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#88cc00",
                "fill-opacity": 0.2
            }
        },
        {
            "id": "landmark-zoo-attraction",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-0-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "tourism",
                    "attraction"
                ],
                [
                    "==",
                    "tourism",
                    "zoo"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#d4dece",
                "fill-opacity": 0.4
            }
        },
        {
            "id": "landmark-cemetery",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-1-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 10.384608730578963,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "landuse",
                    "cemetery"
                ],
                [
                    "==",
                    "landuse",
                    "grave_yard"
                ],
                [
                    "==",
                    "amenity",
                    "grave_yard"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#80ce56",
                "fill-opacity": 0.6
            }
        },
        {
            "id": "landmark-beach",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-13-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 10.384608730578963,
            "maxzoom": 24,
            "filter": [
                "==",
                "natural",
                "beach"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#f5efd7"
            }
        },
        {
            "id": "landmark-parking",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-14-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "parking"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#fffef2"
            }
        },
        {
            "id": "landmark-sport-stadium",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-15-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 11.384638023421234,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "leisure",
                    "stadium"
                ],
                [
                    "==",
                    "leisure",
                    "sports_centre"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#c1e5ae"
            }
        },
        {
            "id": "landmark-hospital",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-17-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 10.384608730578963,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "hospital"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#f5d1dd",
                "fill-opacity": 0.4
            }
        },
        {
            "id": "landmark-retail",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-18-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 11.384638023421234,
            "maxzoom": 24,
            "filter": [
                "==",
                "landuse",
                "retail"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#f0e7bc",
                "fill-opacity": 0.4
            }
        },
        {
            "id": "landmark-aeroway-runway",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-20-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "filter": [
                "==",
                "aeroway",
                "runway"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#b4b4b4"
            }
        },
        {
            "id": "landmark-aeroway-building",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark-landmark-19-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "aeroway",
                    "apron"
                ],
                [
                    "==",
                    "aeroway",
                    "aerodrome"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#ddddde"
            }
        },
        {
            "id": "landmark-lowz-greenland-nature",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark_lowzoom-landmark-lowzoom-0-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark_lowzoom",
            "minzoom": 7.298049766619153,
            "maxzoom": 8.298051830019984,
            "filter": [
                "any",
                [
                    "==",
                    "leisure",
                    "park"
                ],
                [
                    "==",
                    "leisure",
                    "playground"
                ],
                [
                    "==",
                    "landuse",
                    "recreation_ground"
                ],
                [
                    "==",
                    "leisure",
                    "nature_reserve"
                ],
                [
                    "==",
                    "leisure",
                    "common"
                ],
                [
                    "==",
                    "leisure",
                    "pitch"
                ],
                [
                    "==",
                    "landuse",
                    "grass"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#b6e59e",
                "fill-opacity": 0.8
            }
        },
        {
            "id": "landmark-lowz-forest-copy",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "landmark_lowzoom-landmark-lowzoom-1-0"
            },
            "source": "osm i-system",
            "source-layer": "landmark_lowzoom",
            "minzoom": 7.298049766619153,
            "maxzoom": 8.298051830019984,
            "filter": [
                "any",
                [
                    "==",
                    "landuse",
                    "forest"
                ],
                [
                    "==",
                    "landuse",
                    "recreation_ground"
                ],
                [
                    "==",
                    "landuse",
                    "conservation"
                ],
                [
                    "==",
                    "natural",
                    "wood"
                ],
                [
                    "==",
                    "landuse",
                    "wood"
                ],
                [
                    "==",
                    "natural",
                    "scrub"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#80ce56",
                "fill-opacity": 0.8
            }
        },
        {
            "id": "water_lines-stream-canal",
            "type": "line",
            "metadata": {
                "maputnik:comment": "water_lines-water_lines-2-0"
            },
            "source": "osm i-system",
            "source-layer": "water_lines",
            "minzoom": 9.38459541712555,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "waterway",
                    "river"
                ],
                [
                    "all",
                    [
                        "==",
                        "waterway",
                        "canal"
                    ],
                    [
                        "any",
                        [
                            "none",
                            [
                                "has",
                                "disused"
                            ]
                        ],
                        [
                            "!=",
                            "disused",
                            "yes"
                        ]
                    ]
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#8bcfde",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            1
                        ],
                        [
                            16.108388162092307,
                            2
                        ],
                        [
                            22,
                            3
                        ]
                    ]
                }
            }
        },
        {
            "id": "water_lines-canal-disused",
            "type": "line",
            "metadata": {
                "maputnik:comment": "water_lines-water_lines-5-0"
            },
            "source": "osm i-system",
            "source-layer": "water_lines",
            "minzoom": 9.38459541712555,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "waterway",
                    "derelict_canal"
                ],
                [
                    "all",
                    [
                        "==",
                        "waterway",
                        "canal"
                    ],
                    [
                        "==",
                        "disused",
                        "yes"
                    ]
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#8bcfde",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            1
                        ],
                        [
                            16.108388162092307,
                            2
                        ],
                        [
                            22,
                            3
                        ]
                    ]
                },
                "line-dasharray": [
                    2,
                    2
                ]
            }
        },
        {
            "id": "water_lines-drain-ditch",
            "type": "line",
            "metadata": {
                "maputnik:comment": "water_lines-water_lines-7-0"
            },
            "source": "osm i-system",
            "source-layer": "water_lines",
            "minzoom": 12.384701950567761,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "waterway",
                    "stream"
                ],
                [
                    "==",
                    "waterway",
                    "drain"
                ],
                [
                    "==",
                    "waterway",
                    "ditch"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#8bcfde",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            1
                        ],
                        [
                            22,
                            2
                        ]
                    ]
                }
            }
        },
        {
            "id": "water_areas-glacier",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "water_areas-water_areas-0-0"
            },
            "source": "osm i-system",
            "source-layer": "water_areas",
            "minzoom": 4.35213367582087,
            "maxzoom": 24,
            "filter": [
                "==",
                "natural",
                "glacier"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#ffffff"
            }
        },
        {
            "id": "water_areas-dock-canal-fill",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "water_areas-water_areas-1-0"
            },
            "source": "osm i-system",
            "source-layer": "water_areas",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "waterway",
                    "dock"
                ],
                [
                    "==",
                    "waterway",
                    "mill_pond"
                ],
                [
                    "==",
                    "waterway",
                    "canal"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#aadaff"
            }
        },
        {
            "id": "water_areas-dock-canal-outl",
            "type": "line",
            "metadata": {
                "maputnik:comment": "dock or canal"
            },
            "source": "osm i-system",
            "source-layer": "water_areas",
            "minzoom": 7.298049766619153,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "waterway",
                    "dock"
                ],
                [
                    "==",
                    "waterway",
                    "mill_pond"
                ],
                [
                    "==",
                    "waterway",
                    "canal"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#8bcfde",
                "line-width": 1
            }
        },
        {
            "id": "water_areas-basin-fill",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "water_areas-water_areas-2-0"
            },
            "source": "osm i-system",
            "source-layer": "water_areas",
            "minzoom": 5.406219255539338,
            "maxzoom": 24,
            "filter": [
                "==",
                "landuse",
                "basin"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#aadaff"
            }
        },
        {
            "id": "water_areas-basin-outl",
            "type": "line",
            "metadata": {
                "maputnik:comment": "water_areas-water_areas-2-1"
            },
            "source": "osm i-system",
            "source-layer": "water_areas",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "filter": [
                "==",
                "landuse",
                "basin"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#8bcfde",
                "line-width": 1
            }
        },
        {
            "id": "water_areas-waterbody-fill",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "water_areas-water_areas-3-0"
            },
            "source": "osm i-system",
            "source-layer": "water_areas",
            "minzoom": 4.35213367582087,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "natural",
                    "lake"
                ],
                [
                    "==",
                    "natural",
                    "water"
                ],
                [
                    "==",
                    "landuse",
                    "reservoir"
                ],
                [
                    "==",
                    "waterway",
                    "riverbank"
                ],
                [
                    "==",
                    "landuse",
                    "water"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#aadaff"
            }
        },
        {
            "id": "water_areas-waterbody-outl",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "water_areas",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "natural",
                    "lake"
                ],
                [
                    "==",
                    "natural",
                    "water"
                ],
                [
                    "==",
                    "landuse",
                    "reservoir"
                ],
                [
                    "==",
                    "waterway",
                    "riverbank"
                ],
                [
                    "==",
                    "landuse",
                    "water"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#8bcfde",
                "line-width": 1
            }
        },
        {
            "id": "ferry_route",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "ferry_route",
            "minzoom": 10.384608730578963,
            "maxzoom": 24,
            "filter": [
                "==",
                "route",
                "ferry"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": 2,
                "line-dasharray": [
                    2,
                    3
                ]
            }
        },
        {
            "id": "building-fill-lowz",
            "type": "fill",
            "source": "osm i-system",
            "source-layer": "building",
            "minzoom": 12.384701950567761,
            "maxzoom": 13.384840532309038,
            "layout": {
                "visibility": "none"
            },
            "paint": {
                "fill-color": "#DDDDDD",
                "fill-opacity": 0.9
            },
            "metadata": {
                "maputnik:comment": "render buildings behind roads in at zoom values"
            }
        },
        {
            "id": "pedestrian_walkway-casing",
            "type": "line",
            "metadata": {
                "maputnik:comment": "pedestrian_walkway-pedestrian-casing-2-0"
            },
            "source": "osm i-system",
            "source-layer": "pedestrian_walkway",
            "minzoom": 14.8,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "pedestrian"
                ],
                [
                    "==",
                    "highway",
                    "track"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            14.384840532309038,
                            "#d3d3d3"
                        ],
                        [
                            14.3848405323094,
                            "#d3d3d3"
                        ]
                    ]
                },
                "line-width": {
                    "stops": [
                        [
                            13.384840532309038,
                            1
                        ],
                        [
                            14.385139355434536,
                            4
                        ],
                        [
                            16.108388162092307,
                            6
                        ]
                    ]
                }
            }
        },
        {
            "id": "pedestrian_walkway-casing-high",
            "type": "line",
            "metadata": {
                "maputnik:comment": "pedestrian_walkway-pedestrian-casing-3-0"
            },
            "source": "osm i-system",
            "source-layer": "pedestrian_walkway",
            "minzoom": 14.5,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "pedestrian"
                ],
                [
                    "==",
                    "highway",
                    "track"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#d3d3d3",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            2.366948538875913
                        ],
                        [
                            24,
                            562.0833141584458
                        ]
                    ]
                }
            }
        },
        {
            "id": "pedestrian_polygon-fill",
            "type": "fill",
            "metadata": {
                "maputnik:comment": "pedestrian_polygon-pedestrian-polygon-0-0"
            },
            "source": "osm i-system",
            "source-layer": "pedestrian_polygon",
            "minzoom": 12,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "living_street"
                ],
                [
                    "==",
                    "highway",
                    "pedestrian"
                ],
                [
                    "==",
                    "highway",
                    "service"
                ],
                [
                    "==",
                    "highway",
                    "footway"
                ],
                [
                    "==",
                    "highway",
                    "path"
                ],
                [
                    "==",
                    "highway",
                    "residential"
                ],
                [
                    "==",
                    "highway",
                    "unclassified"
                ],
                [
                    "==",
                    "highway",
                    "service"
                ],
                [
                    "==",
                    "highway",
                    "track"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#ffffff",
                "fill-outline-color": "#ffffff",
                "fill-antialias": true
            }
        },
        {
            "id": "pedestrian_polygon-outl",
            "type": "line",
            "metadata": {
                "maputnik:comment": "pedestrian_polygon-pedestrian-polygon-0-1"
            },
            "source": "osm i-system",
            "source-layer": "pedestrian_polygon",
            "minzoom": 12,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "living_street"
                ],
                [
                    "==",
                    "highway",
                    "pedestrian"
                ],
                [
                    "==",
                    "highway",
                    "service"
                ],
                [
                    "==",
                    "highway",
                    "footway"
                ],
                [
                    "==",
                    "highway",
                    "path"
                ],
                [
                    "==",
                    "highway",
                    "residential"
                ],
                [
                    "==",
                    "highway",
                    "unclassified"
                ],
                [
                    "==",
                    "highway",
                    "service"
                ],
                [
                    "==",
                    "highway",
                    "track"
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#d3d3d3",
                "line-width": 1
            }
        },
        {
            "id": "rail-line",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "rail",
            "minzoom": 9.38459541712555,
            "maxzoom": 24,
            "filter": [
                "all",
                [
                    "any",
                    [
                        "==",
                        "railway",
                        "rail"
                    ],
                    [
                        "==",
                        "railway",
                        "light_rail"
                    ],
                    [
                        "==",
                        "railway",
                        "monorail"
                    ]
                ],
                [
                    "!=",
                    "tunnel",
                    "yes"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#d3d3d3",
                "line-width": {
                    "stops": [
                        [
                            9.38459541712555,
                            2
                        ],
                        [
                            12.384701950567761,
                            3
                        ],
                        [
                            16.108388162092307,
                            5
                        ]
                    ]
                }
            }
        },
        {
            "id": "rail-dash",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "rail",
            "minzoom": 13.38459541712555,
            "maxzoom": 24,
            "filter": [
                "all",
                [
                    "any",
                    [
                        "==",
                        "railway",
                        "rail"
                    ],
                    [
                        "==",
                        "railway",
                        "light_rail"
                    ],
                    [
                        "==",
                        "railway",
                        "monorail"
                    ]
                ],
                [
                    "!=",
                    "tunnel",
                    "yes"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "stops": [
                        [
                            9.38459541712555,
                            0.5
                        ],
                        [
                            12.384701950567761,
                            1
                        ],
                        [
                            16.108388162092307,
                            2
                        ]
                    ]
                },
                "line-dasharray": {
                    "stops": [
                        [
                            9.38459541712555,
                            [
                                3,
                                3
                            ]
                        ],
                        [
                            12.384701950567761,
                            [
                                4,
                                3
                            ]
                        ],
                        [
                            17.108388162092307,
                            [
                                5,
                                4
                            ]
                        ]
                    ]
                }
            }
        },
        {
            "id": "runway-runw",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "runway",
            "minzoom": 10.384608730578963,
            "maxzoom": 15.108002780993148,
            "filter": [
                "==",
                "aeroway",
                "runway"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#b4b4b4",
                "line-width": {
                    "stops": [
                        [
                            12.384701950567761,
                            3
                        ],
                        [
                            14.385139355434536,
                            5
                        ],
                        [
                            15.108002780993148,
                            15
                        ]
                    ]
                }
            }
        },
        {
            "id": "runway-runw-highz",
            "type": "line",
            "metadata": {
                "maputnik:comment": "runway-runway-3-0"
            },
            "source": "osm i-system",
            "source-layer": "runway",
            "minzoom": 15.108002780993148,
            "maxzoom": 24,
            "filter": [
                "==",
                "aeroway",
                "runway"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#b4b4b4",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            15.108002780993148,
                            15.212033680125728
                        ],
                        [
                            24,
                            7226.785467751436
                        ]
                    ]
                }
            }
        },
        {
            "id": "runway-taxi",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "runway",
            "minzoom": 10.384608730578963,
            "maxzoom": 15.108002780993148,
            "filter": [
                "==",
                "aeroway",
                "taxiway"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#b4b4b4",
                "line-width": {
                    "stops": [
                        [
                            12.384701950567761,
                            1
                        ],
                        [
                            14.385139355434536,
                            2
                        ],
                        [
                            15.108002780993148,
                            5
                        ]
                    ]
                }
            }
        },
        {
            "id": "runway-taxi-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "runway",
            "minzoom": 15.108002780993148,
            "maxzoom": 24,
            "filter": [
                "==",
                "aeroway",
                "taxiway"
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#b4b4b4",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            15.108002780993148,
                            6.760903857833657
                        ],
                        [
                            24,
                            3211.9046523339716
                        ]
                    ]
                }
            }
        },
        {
            "id": "alley_casing",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "alley",
            "minzoom": 14.8,
            "maxzoom": 16.108388162092307,
            "filter": [
                "==",
                "highway",
                "service"
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            13.384840532309038,
                            "#d3d3d3"
                        ],
                        [
                            13.3848405323094,
                            "#d3d3d3"
                        ]
                    ]
                },
                "line-width": {
                    "stops": [
                        [
                            13.384840532309038,
                            0.5
                        ],
                        [
                            14.385139355434536,
                            1
                        ],
                        [
                            16.108388162092307,
                            4.5
                        ]
                    ]
                }
            }
        },
        {
            "id": "alley_casing-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "alley",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "==",
                "highway",
                "service"
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#d3d3d3",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            4
                        ],
                        [
                            24,
                            562.0833141584458
                        ]
                    ]
                }
            }
        },
        {
            "id": "tertiary-casing",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "tertiary",
            "minzoom": 12.384701950567761,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "tertiary"
                ],
                [
                    "==",
                    "highway",
                    "residential"
                ],
                [
                    "==",
                    "highway",
                    "living_street"
                ],
                [
                    "==",
                    "highway",
                    "unclassified"
                ],
                [
                    "==",
                    "highway",
                    "tertiary_link"
                ],
                [
                    "==",
                    "highway",
                    "residential_link"
                ],
                [
                    "==",
                    "highway",
                    "unclassified_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            14.385139355434536,
                            "#d3d3d3"
                        ],
                        [
                            14.385139355434537,
                            "#d3d3d3"
                        ]
                    ]
                },
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            3.5
                        ],
                        [
                            16.108388162092307,
                            7
                        ]
                    ]
                }
            }
        },
        {
            "id": "tertiary_casing-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "tertiary",
            "minzoom": 15.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "tertiary"
                ],
                [
                    "==",
                    "highway",
                    "residential"
                ],
                [
                    "==",
                    "highway",
                    "living_street"
                ],
                [
                    "==",
                    "highway",
                    "unclassified"
                ],
                [
                    "==",
                    "highway",
                    "tertiary_link"
                ],
                [
                    "==",
                    "highway",
                    "residential_link"
                ],
                [
                    "==",
                    "highway",
                    "unclassified_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#aaaaaa",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            5
                        ],
                        [
                            24,
                            1204
                        ]
                    ]
                }
            }
        },
        {
            "id": "secondary-casing",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "secondary",
            "minzoom": 10.384608730578963,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "secondary"
                ],
                [
                    "==",
                    "highway",
                    "secondary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#d3d3d3",
                "line-width": {
                    "stops": [
                        [
                            12.384701950567761,
                            3
                        ],
                        [
                            14.385139355434536,
                            5
                        ]
                    ]
                }
            }
        },
        {
            "id": "secondary-casing-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "secondary",
            "minzoom": 15.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "secondary"
                ],
                [
                    "==",
                    "highway",
                    "secondary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#aaaaaa",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            6
                        ],
                        [
                            24,
                            1525
                        ]
                    ]
                }
            }
        },
        {
            "id": "primary-casing",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "primary_road",
            "minzoom": 10.384608730578963,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "primary"
                ],
                [
                    "==",
                    "highway",
                    "primary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#f0ca00",
                "line-width": {
                    "stops": [
                        [
                            10.384608730578963,
                            2
                        ],
                        [
                            12.384701950567761,
                            3
                        ],
                        [
                            13.384840532309038,
                            4
                        ],
                        [
                            14.385139355434536,
                            5
                        ],
                        [
                            16.108388162092307,
                            10
                        ]
                    ]
                }
            }
        },
        {
            "id": "primary-casing-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "primary_road",
            "minzoom": 15.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "primary"
                ],
                [
                    "==",
                    "highway",
                    "primary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#f0ca00",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            6
                        ],
                        [
                            24,
                            1847
                        ]
                    ]
                }
            }
        },
        {
            "id": "trunk-casing",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "trunk",
            "minzoom": 7.298049766619153,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "trunk"
                ],
                [
                    "==",
                    "highway",
                    "trunk_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            8.298051830019984,
                            "#f0ca00"
                        ],
                        [
                            8.298051830019986,
                            "#f0ca00"
                        ]
                    ]
                },
                "line-width": {
                    "stops": [
                        [
                            8.298051830019984,
                            2
                        ],
                        [
                            9.38459541712555,
                            3
                        ],
                        [
                            12.384701950567761,
                            4
                        ],
                        [
                            14.385139355434536,
                            5
                        ],
                        [
                            16.108388162092307,
                            10
                        ]
                    ]
                }
            }
        },
        {
            "id": "trunk-casing-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "trunk",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "trunk"
                ],
                [
                    "==",
                    "highway",
                    "trunk_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#f0ca00",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            10
                        ],
                        [
                            24,
                            2007
                        ]
                    ]
                },
                "line-offset": 0
            }
        },
        {
            "id": "pedestrian_walkway",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "pedestrian_walkway",
            "minzoom": 14.5,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "pedestrian"
                ],
                [
                    "==",
                    "highway",
                    "track"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            1
                        ],
                        [
                            16.108388162092307,
                            4
                        ]
                    ]
                }
            }
        },
        {
            "id": "pedestrian_walkway-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "pedestrian_walkway",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "pedestrian"
                ],
                [
                    "==",
                    "highway",
                    "track"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            2.028813033322211
                        ],
                        [
                            24,
                            481.7856978500963
                        ]
                    ]
                }
            }
        },
        {
            "id": "pedestrian_walkway-highway",
            "type": "line",
            "metadata": {
                "maputnik:comment": "not pedestrian"
            },
            "source": "osm i-system",
            "source-layer": "pedestrian_walkway",
            "minzoom": 14.8,
            "maxzoom": 16.108388162092307,
            "filter": [
                "none",
                [
                    "any",
                    [
                        "==",
                        "highway",
                        "pedestrian"
                    ],
                    [
                        "==",
                        "highway",
                        "track"
                    ]
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            14.385139355434536,
                            "#ffffff"
                        ],
                        [
                            14.385139355434537,
                            "#ffffff"
                        ]
                    ]
                },
                "line-width": {
                    "stops": [
                        [
                            13.384840532309038,
                            0.25
                        ],
                        [
                            14.385139355434536,
                            0.5
                        ],
                        [
                            14.385139355434537,
                            1.25
                        ],
                        [
                            16.108388162092307,
                            1.75
                        ]
                    ]
                }
            }
        },
        {
            "id": "pedestrian_walkway-highway-highz",
            "type": "line",
            "metadata": {
                "maputnik:comment": "not pedestrian"
            },
            "source": "osm i-system",
            "source-layer": "pedestrian_walkway",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "none",
                [
                    "any",
                    [
                        "==",
                        "highway",
                        "pedestrian"
                    ],
                    [
                        "==",
                        "highway",
                        "track"
                    ]
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            1.0144065166611056
                        ],
                        [
                            24,
                            240.89284892504816
                        ]
                    ]
                }
            }
        },
        {
            "id": "alley",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "alley",
            "minzoom": 14.8,
            "maxzoom": 16.108388162092307,
            "filter": [
                "==",
                "highway",
                "service"
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            1
                        ],
                        [
                            16.108388162092307,
                            3
                        ]
                    ]
                }
            }
        },
        {
            "id": "alley-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "alley",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "==",
                "highway",
                "service"
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            3
                        ],
                        [
                            24,
                            481.7856978500963
                        ]
                    ]
                }
            }
        },
        {
            "id": "tertiary",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "tertiary",
            "minzoom": 12.384608730578963,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "tertiary"
                ],
                [
                    "==",
                    "highway",
                    "residential"
                ],
                [
                    "==",
                    "highway",
                    "living_street"
                ],
                [
                    "==",
                    "highway",
                    "unclassified"
                ],
                [
                    "==",
                    "highway",
                    "tertiary_link"
                ],
                [
                    "==",
                    "highway",
                    "residential_link"
                ],
                [
                    "==",
                    "highway",
                    "unclassified_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            12.384701950567761,
                            "#ffffff"
                        ],
                        [
                            12.38470195056777,
                            "#ffffff"
                        ]
                    ]
                },
                "line-width": {
                    "stops": [
                        [
                            12.384701950567761,
                            2
                        ],
                        [
                            14.385139355434536,
                            3
                        ],
                        [
                            16.108388162092307,
                            6
                        ]
                    ]
                },
                "line-opacity": {
                    "stops": [
                        [
                            11.384638023421234,
                            0.5
                        ],
                        [
                            11.384638023421235,
                            1
                        ]
                    ]
                }
            }
        },
        {
            "id": "tertiary-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "tertiary",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "tertiary"
                ],
                [
                    "==",
                    "highway",
                    "residential"
                ],
                [
                    "==",
                    "highway",
                    "living_street"
                ],
                [
                    "==",
                    "highway",
                    "unclassified"
                ],
                [
                    "==",
                    "highway",
                    "tertiary_link"
                ],
                [
                    "==",
                    "highway",
                    "residential_link"
                ],
                [
                    "==",
                    "highway",
                    "unclassified_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            6
                        ],
                        [
                            24,
                            1124
                        ]
                    ]
                }
            }
        },
        {
            "id": "motorway-casing",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "motorway",
            "minzoom": 7.298049766619153,
            "maxzoom": 16.108388162092307,
            "filter": [
                "==",
                "highway",
                "motorway"
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible",
                "line-cap": "round"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "stops": [
                        [
                            8.298051830019984,
                            5
                        ],
                        [
                            12.384701950567761,
                            6
                        ],
                        [
                            14.385139355434536,
                            8
                        ],
                        [
                            16.108388162092307,
                            14
                        ]
                    ]
                }
            }
        },
        {
            "id": "motorway-casing-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "motorway",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "==",
                "highway",
                "motorway"
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible",
                "line-cap": "round"
            },
            "paint": {
                "line-color": "#017cc2",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            16
                        ],
                        [
                            24,
                            2408.928489250482
                        ]
                    ]
                }
            }
        },
        {
            "id": "interchange",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "interchange",
            "minzoom": 6.298048863890273,
            "maxzoom": 16.108388162092307,
            "filter": [
                "==",
                "highway",
                "motorway_link"
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#5b8da9",
                "line-width": {
                    "stops": [
                        [
                            9.38459541712555,
                            3
                        ],
                        [
                            14.385139355434536,
                            3
                        ],
                        [
                            16.108388162092307,
                            8
                        ]
                    ]
                }
            }
        },
        {
            "id": "interchange-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "interchange",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "==",
                "highway",
                "motorway_link"
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#5b8da9",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            6.086439099966634
                        ],
                        [
                            24,
                            1445.357093550289
                        ]
                    ]
                }
            }
        },
        {
            "id": "trunk",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "trunk",
            "minzoom": 5.406219255539338,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "trunk"
                ],
                [
                    "==",
                    "highway",
                    "trunk_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            6.298048863890273,
                            "#fff2af"
                        ],
                        [
                            6.298048863890275,
                            "#fff2af"
                        ],
                        [
                            8.298051830019984,
                            "#fff2af"
                        ],
                        [
                            8.298051830019986,
                            "#fff2af"
                        ]
                    ]
                },
                "line-width": {
                    "stops": [
                        [
                            6.298048863890273,
                            1
                        ],
                        [
                            7.298049766619153,
                            1
                        ],
                        [
                            8.298051830019984,
                            0.5
                        ],
                        [
                            9.38459541712555,
                            1
                        ],
                        [
                            12.384701950567761,
                            1.5
                        ],
                        [
                            14.385139355434536,
                            3
                        ],
                        [
                            16.108388162092307,
                            7
                        ]
                    ]
                },
                "line-opacity": {
                    "stops": [
                        [
                            6.298048863890273,
                            0.75
                        ],
                        [
                            6.298048863890275,
                            1
                        ]
                    ]
                }
            }
        },
        {
            "id": "trunk-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "trunk",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "trunk"
                ],
                [
                    "==",
                    "highway",
                    "trunk_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#fff2af",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            8
                        ],
                        [
                            24,
                            1927
                        ]
                    ]
                }
            }
        },
        {
            "id": "secondary",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "secondary",
            "minzoom": 8.298048863890273,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "secondary"
                ],
                [
                    "==",
                    "highway",
                    "secondary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            10.384608730578963,
                            "#ffffff"
                        ],
                        [
                            10.384608730578964,
                            "#ffffff"
                        ]
                    ]
                },
                "line-width": {
                    "stops": [
                        [
                            8.298051830019984,
                            1
                        ],
                        [
                            10.384608730578963,
                            2
                        ],
                        [
                            12.384701950567761,
                            2
                        ],
                        [
                            14.385139355434536,
                            4
                        ],
                        [
                            16.108388162092307,
                            6
                        ]
                    ]
                }
            }
        },
        {
            "id": "secondary-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "secondary",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "secondary"
                ],
                [
                    "==",
                    "highway",
                    "secondary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            8
                        ],
                        [
                            24,
                            1445
                        ]
                    ]
                }
            }
        },
        {
            "id": "primary",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "primary_road",
            "minzoom": 5.406219255539338,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "primary"
                ],
                [
                    "==",
                    "highway",
                    "primary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            10.384608730578963,
                            "#fff4be"
                        ],
                        [
                            10.384608730578964,
                            "#fff4be"
                        ]
                    ]
                },
                "line-width": {
                    "stops": [
                        [
                            5.406219255539338,
                            2
                        ],
                        [
                            10.384608730578963,
                            3
                        ],
                        [
                            10.384608730578964,
                            3
                        ],
                        [
                            12.384701950567761,
                            3
                        ],
                        [
                            13.384840532309038,
                            4
                        ],
                        [
                            14.385139355434536,
                            6
                        ],
                        [
                            16.108388162092307,
                            10
                        ]
                    ]
                }
            }
        },
        {
            "id": "primary-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "primary_road",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "primary"
                ],
                [
                    "==",
                    "highway",
                    "primary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#fff4be",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            6
                        ],
                        [
                            24,
                            1766
                        ]
                    ]
                }
            }
        },
        {
            "id": "motorway",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "motorway",
            "minzoom": 1,
            "maxzoom": 16.108388162092307,
            "filter": [
                "==",
                "highway",
                "motorway"
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible",
                "line-cap": "round"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            7.298049766619153,
                            "#5b8da9"
                        ],
                        [
                            7.298049766619155,
                            "#5b8da9"
                        ]
                    ]
                },
                "line-width": {
                    "stops": [
                        [
                            1,
                            2
                        ],
                        [
                            7.298049766619153,
                            2.5
                        ],
                        [
                            7.298049766619155,
                            2
                        ],
                        [
                            12.384701950567763,
                            2
                        ],
                        [
                            14.385139355434536,
                            4
                        ],
                        [
                            16.108388162092307,
                            9
                        ]
                    ]
                }
            }
        },
        {
            "id": "motorway-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "motorway",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "==",
                "highway",
                "motorway"
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible",
                "line-cap": "round"
            },
            "paint": {
                "line-color": "#5b8da9",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            11
                        ],
                        [
                            24,
                            1284.7618609335902
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-cas-motorway",
            "type": "line",
            "metadata": {
                "maputnik:comment": "motorway"
            },
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "==",
                "highway",
                "motorway"
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            9
                        ],
                        [
                            16.108388162092307,
                            16
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-cas-motorway-highz",
            "type": "line",
            "metadata": {
                "maputnik:comment": "motorway"
            },
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "==",
                "highway",
                "motorway"
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#017cc2",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            19
                        ],
                        [
                            24,
                            2569.5237218671805
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-cas-trunk",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "trunk"
                ],
                [
                    "==",
                    "highway",
                    "trunk_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#f0ca00",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            6
                        ],
                        [
                            16.108388162092307,
                            12
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-cas-trunk-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "trunk"
                ],
                [
                    "==",
                    "highway",
                    "trunk_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#f0ca00",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            12
                        ],
                        [
                            24,
                            2087
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-cas-primary",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "primary"
                ],
                [
                    "==",
                    "highway",
                    "primary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#aaaaaa",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            8
                        ],
                        [
                            16.108388162092307,
                            12
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-cas-primary-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "primary"
                ],
                [
                    "==",
                    "highway",
                    "primary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#aaaaaa",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            10
                        ],
                        [
                            24,
                            1950
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-cas-secondary",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "secondary"
                ],
                [
                    "==",
                    "highway",
                    "secondary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#aaaaaa",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            6
                        ],
                        [
                            16.108388162092307,
                            10
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-cas-secondary-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "secondary"
                ],
                [
                    "==",
                    "highway",
                    "secondary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#aaaaaa",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            11
                        ],
                        [
                            24,
                            1606
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-cas-tertiary",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "tertiary"
                ],
                [
                    "==",
                    "highway",
                    "residential"
                ],
                [
                    "==",
                    "highway",
                    "unclassified"
                ],
                [
                    "==",
                    "highway",
                    "tertiary_link"
                ],
                [
                    "==",
                    "highway",
                    "residential_link"
                ],
                [
                    "==",
                    "highway",
                    "unclassified_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#d3d3d3",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            3.5
                        ],
                        [
                            16.108388162092307,
                            7
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-cas-tertiary-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "tertiary"
                ],
                [
                    "==",
                    "highway",
                    "residential"
                ],
                [
                    "==",
                    "highway",
                    "unclassified"
                ],
                [
                    "==",
                    "highway",
                    "tertiary_link"
                ],
                [
                    "==",
                    "highway",
                    "residential_link"
                ],
                [
                    "==",
                    "highway",
                    "unclassified_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#aaaaaa",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            6
                        ],
                        [
                            24,
                            1204
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-motorway",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "==",
                "highway",
                "motorway"
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#5b8da9",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            4
                        ],
                        [
                            16.108388162092307,
                            9
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-motorway-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "==",
                "highway",
                "motorway"
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#5b8da9",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            11
                        ],
                        [
                            24,
                            1284.7618609335902
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-moto-link",
            "type": "line",
            "metadata": {
                "maputnik:comment": "motorway link"
            },
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "==",
                "highway",
                "motorway_link"
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#5b8da9",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            4
                        ],
                        [
                            16.108388162092307,
                            10
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-moto-link-highz",
            "type": "line",
            "metadata": {
                "maputnik:comment": "motorway link"
            },
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "==",
                "highway",
                "motorway_link"
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#5b8da9",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            9
                        ],
                        [
                            24,
                            1766.5475587836866
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-trunk",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "trunk"
                ],
                [
                    "==",
                    "highway",
                    "trunk_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#fff2af",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            3
                        ],
                        [
                            16.108388162092307,
                            7
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-trunk-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "trunk"
                ],
                [
                    "==",
                    "highway",
                    "trunk_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#fff2af",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            8
                        ],
                        [
                            24,
                            1927
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-primary",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "primary"
                ],
                [
                    "==",
                    "highway",
                    "primary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#fff2af",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            4
                        ],
                        [
                            16.108388162092307,
                            8
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-primary-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "primary"
                ],
                [
                    "==",
                    "highway",
                    "primary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#fff2af",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            6
                        ],
                        [
                            24,
                            1766
                        ]
                    ]
                }
            },
            "metadata": {
                "maputnik:comment": "test"
            }
        },
        {
            "id": "bridge-secondary",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "secondary"
                ],
                [
                    "==",
                    "highway",
                    "secondary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            3
                        ],
                        [
                            16.108388162092307,
                            5
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-secondary-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "secondary"
                ],
                [
                    "==",
                    "highway",
                    "secondary_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            6
                        ],
                        [
                            24,
                            1445
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-tertiary",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "tertiary"
                ],
                [
                    "==",
                    "highway",
                    "residential"
                ],
                [
                    "==",
                    "highway",
                    "unclassified"
                ],
                [
                    "==",
                    "highway",
                    "tertiary_link"
                ],
                [
                    "==",
                    "highway",
                    "residential_link"
                ],
                [
                    "==",
                    "highway",
                    "unclassified_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "stops": [
                        [
                            14.385139355434536,
                            1.5
                        ],
                        [
                            16.108388162092307,
                            5
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-tertiary-highz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 16.108388162092307,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "highway",
                    "tertiary"
                ],
                [
                    "==",
                    "highway",
                    "residential"
                ],
                [
                    "==",
                    "highway",
                    "unclassified"
                ],
                [
                    "==",
                    "highway",
                    "tertiary_link"
                ],
                [
                    "==",
                    "highway",
                    "residential_link"
                ],
                [
                    "==",
                    "highway",
                    "unclassified_link"
                ]
            ],
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [
                            16.108388162092307,
                            5
                        ],
                        [
                            24,
                            1124
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-rail-line",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "railway",
                    "rail"
                ],
                [
                    "==",
                    "railway",
                    "light_rail"
                ],
                [
                    "==",
                    "railway",
                    "subway"
                ],
                [
                    "==",
                    "railway",
                    "monorail"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#afafaf",
                "line-width": {
                    "stops": [
                        [
                            9.38459541712555,
                            3
                        ],
                        [
                            12.384701950567761,
                            4
                        ],
                        [
                            16.108388162092307,
                            7
                        ]
                    ]
                }
            }
        },
        {
            "id": "bridge-rail-dash",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "bridge",
            "minzoom": 13.384840532309038,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "railway",
                    "rail"
                ],
                [
                    "==",
                    "railway",
                    "light_rail"
                ],
                [
                    "==",
                    "railway",
                    "subway"
                ],
                [
                    "==",
                    "railway",
                    "monorail"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#ffffff",
                "line-width": {
                    "stops": [
                        [
                            9.38459541712555,
                            1
                        ],
                        [
                            12.384701950567761,
                            1.5
                        ],
                        [
                            16.108388162092307,
                            3
                        ]
                    ]
                },
                "line-dasharray": {
                    "stops": [
                        [
                            9.38459541712555,
                            [
                                3,
                                3
                            ]
                        ],
                        [
                            12.384701950567761,
                            [
                                4,
                                3
                            ]
                        ],
                        [
                            16.108388162092307,
                            [
                                5,
                                4
                            ]
                        ]
                    ]
                }
            }
        },
        {
            "id": "building-fill",
            "type": "fill",
            "source": "osm i-system",
            "source-layer": "building",
            "minzoom": 15.35,
            "maxzoom": 24,
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#e2e2e2",
                "fill-opacity": 0.75
            }
        },
        {
            "id": "building_special-fill",
            "type": "fill",
            "source": "osm i-system",
            "source-layer": "building_special",
            "minzoom": 15.2,
            "maxzoom": 24,
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#fef7ee",
                "fill-opacity": 1
            }
        },
        {
            "id": "building-outline",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "building",
            "minzoom": 15.5,
            "maxzoom": 24,
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#d3d3d3",
                "line-opacity": 1,
                "line-width": 0.7
            }
        },
        {
            "id": "building-hover",
            "type": "fill",
            "source": "osm i-system",
            "source-layer": "building_special",
            "minzoom": 1,
            "maxzoom": 24,
            "filter": [
                "all",
                [
                    "==",
                    "osm_id",
                    ""
                ]
            ],
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "#f78d5f",
                "fill-opacity": 1
            }
        },
        {
            "id": "rail-tunnel",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "rail",
            "minzoom": 14.5,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "tunnel",
                    "yes"
                ]
            ],
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#afafaf",
                "line-width": {
                    "stops": [
                        [
                            9.38459541712555,
                            1
                        ],
                        [
                            12.384701950567761,
                            1.5
                        ],
                        [
                            16.108388162092307,
                            3
                        ]
                    ]
                },
                "line-dasharray": {
                    "stops": [
                        [
                            14,
                            [
                                3,
                                2
                            ]
                        ],
                        [
                            17.108388162092307,
                            [
                                5,
                                4
                            ]
                        ]
                    ]
                },
                "line-opacity": 0.5
            }
        },
        {
            "id": "symbol-peak",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 10.384608730578963,
            "maxzoom": 24,
            "filter": [
                "all",
                [
                    "==",
                    "natural",
                    "peak"
                ],
                [
                    "has",
                    "name"
                ]
            ],
            "layout": {
                "icon-image": "peak",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "visibility": "visible",
                "text-field": [
                    "step",
                    [
                        "zoom"
                    ],
                    [
                        "get",
                        "name"
                    ],
                    13,
                    [
                        "case",
                        [
                            "has",
                            "ele"
                        ],
                        [
                            "concat",
                            [
                                "get",
                                "name"
                            ],
                            "\n",
                            [
                                "get",
                                "ele"
                            ],
                            " m"
                        ],
                        [
                            "get",
                            "name"
                        ]
                    ]
                ],
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "text-offset": [
                    0,
                    1.5
                ],
                "text-justify": "center"
            },
            "paint": {
                "text-color": "#775d47",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -8
                ]
            }
        },
        {
            "id": "symbol-moto-junction",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 10.384608730578963,
            "maxzoom": 24,
            "filter": [
                "==",
                "highway",
                "motorway_junction"
            ],
            "layout": {
                "icon-image": "autobahn-ausfahrt",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "visibility": "visible"
            }
        },
        {
            "id": "symbol-golf",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 12.384638023421234,
            "maxzoom": 24,
            "filter": [
                "==",
                "leisure",
                "golf_course"
            ],
            "layout": {
                "icon-image": {
                    "stops": [
                        [
                            12.384701950567761,
                            "golf_small"
                        ],
                        [
                            12.384701950567763,
                            "golf"
                        ]
                    ]
                },
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "visibility": "visible"
            },
            "metadata": {
                "maputnik:comment": "im aktuellen kartengebiet keine daten"
            }
        },
        {
            "id": "symbol-ferry",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 12.384701950567761,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "ferry_terminal"
            ],
            "layout": {
                "icon-image": "ferry",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "visibility": "visible"
            }
        },
        {
            "id": "symbol-hospital",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 13.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "hospital"
            ],
            "layout": {
                "icon-image": "Krankenhaus",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-size": 0.2,
                "visibility": "visible",
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "text-padding": 0,
                "text-allow-overlap": false,
                "text-ignore-placement": false,
                "text-offset": [
                    0,
                    2
                ],
                "text-optional": true
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -11
                ]
            }
        },
        {
            "id": "symbol-police",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 13.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "police"
            ],
            "layout": {
                "icon-image": "Polizei",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-size": 0.2,
                "visibility": "visible",
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "text-padding": 0,
                "text-allow-overlap": false,
                "text-ignore-placement": false,
                "text-offset": [
                    0,
                    2
                ],
                "text-optional": true
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -11
                ]
            }
        },
        {
            "id": "symbol-religious",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 13.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "place_of_worship"
            ],
            "layout": {
                "icon-image": "Kirche",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-size": 0.2,
                "visibility": "visible",
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "text-padding": 0,
                "text-allow-overlap": false,
                "text-ignore-placement": false,
                "text-offset": [
                    0,
                    2
                ],
                "text-optional": true
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -11
                ]
            }
        },
        {
            "id": "symbol-theatre",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 14.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "theatre"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "visibility": "visible",
                "text-padding": 0,
                "text-allow-overlap": false,
                "text-ignore-placement": false,
                "text-offset": [
                    0,
                    2
                ],
                "icon-allow-overlap": true,
                "icon-image": "Theater",
                "icon-ignore-placement": false,
                "icon-size": 0.2,
                "text-optional": true
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -11
                ]
            }
        },
        {
            "id": "symbol-taxi",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 14.385139355434536,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "taxi"
            ],
            "layout": {
                "icon-image": "Taxi",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-size": 0.2,
                "visibility": "visible"
            }
        },
        {
            "id": "symbol-swimming",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 13.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "sport",
                "swimming"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "visibility": "visible",
                "text-padding": 0,
                "text-allow-overlap": false,
                "text-ignore-placement": false,
                "text-offset": [
                    0,
                    2
                ],
                "icon-image": "Hallenbad",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-size": 0.2,
                "text-optional": true
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -11
                ]
            }
        },
        {
            "id": "symbol-marina",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol",
            "minzoom": 1,
            "maxzoom": 24,
            "filter": [
                "==",
                "leisure",
                "marina"
            ],
            "layout": {
                "icon-image": "marina",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "visibility": "visible"
            }
        },
        {
            "id": "admin-lowz",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "admin",
            "minzoom": 2.35213357107188,
            "maxzoom": 8.298051830019984,
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": {
                    "stops": [
                        [
                            5.4,
                            "#50705a"
                        ],
                        [
                            5.8,
                            "#3d6f4c"
                        ]
                    ]
                },
                "line-opacity": 0.2,
                "line-width": {
                    "stops": [
                        [
                            5.4,
                            0.6
                        ],
                        [
                            5.8,
                            2
                        ]
                    ]
                }
            }
        },
        {
            "id": "admin-shadow",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "admin",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "layout": {
                "visibility": "visible",
                "line-join": "round"
            },
            "paint": {
                "line-color": "#838c86",
                "line-opacity": 0.1,
                "line-width": 7
            }
        },
        {
            "id": "admin-dash",
            "type": "line",
            "source": "osm i-system",
            "source-layer": "admin",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "layout": {
                "line-join": "round",
                "line-cap": "round",
                "visibility": "visible"
            },
            "paint": {
                "line-color": "#50705a",
                "line-dasharray": [
                    4,
                    8
                ]
            }
        },
        {
            "id": "symbol_poly-peak",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 10.384608730578963,
            "maxzoom": 24,
            "filter": [
                "==",
                "natural",
                "peak"
            ],
            "layout": {
                "icon-image": "peak",
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "visibility": "visible"
            }
        },
        {
            "id": "symbol_poly-moto-junction",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 10.384608730578963,
            "maxzoom": 24,
            "filter": [
                "==",
                "highway",
                "motorway_junction"
            ],
            "layout": {
                "icon-image": "autobahn-ausfahrt",
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "visibility": "visible"
            },
            "metadata": {
                "maputnik:comment": "im aktuellen kartengebiet keine daten"
            }
        },
        {
            "id": "symbol_poly-golf-small",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 11.384638023421234,
            "maxzoom": 12.384701950567761,
            "filter": [
                "==",
                "leisure",
                "golf_course"
            ],
            "layout": {
                "icon-image": "golf_small",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "visibility": "visible"
            },
            "metadata": {
                "maputnik:comment": "im aktuellen kartengebiet keine daten"
            }
        },
        {
            "id": "symbol_poly-golf",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 14.384701950567761,
            "maxzoom": 24,
            "filter": [
                "==",
                "leisure",
                "golf_course"
            ],
            "layout": {
                "icon-image": "golf",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "visibility": "visible"
            },
            "metadata": {
                "maputnik:comment": "im aktuellen kartengebiet keine daten"
            }
        },
        {
            "id": "symbol_poly_ferry",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 12.384701950567761,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "ferry_terminal"
            ],
            "layout": {
                "icon-image": "ferry",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "visibility": "visible"
            },
            "metadata": {
                "maputnik:comment": "im aktuellen kartengebiet keine daten"
            }
        },
        {
            "id": "symbol_poly-hospital",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 14.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "hospital"
            ],
            "layout": {
                "icon-image": "Krankenhaus",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-size": 0.2,
                "visibility": "visible",
                "text-allow-overlap": false,
                "text-ignore-placement": false
            }
        },
        {
            "id": "symbol_poly-religious",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 14.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "place_of_worship"
            ],
            "layout": {
                "icon-image": "Kirche",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-size": 0.2,
                "visibility": "visible",
                "icon-optional": false,
                "icon-padding": 0,
                "icon-keep-upright": false,
                "icon-offset": [
                    0,
                    0
                ]
            },
            "paint": {
                "icon-translate": [
                    0,
                    0
                ]
            }
        },
        {
            "id": "symbol_poly-police",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 14.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "police"
            ],
            "layout": {
                "icon-image": "Polizei",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-size": 0.2,
                "visibility": "visible"
            }
        },
        {
            "id": "symbol_poly-theatre",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 14.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "theatre"
            ],
            "layout": {
                "visibility": "visible",
                "icon-image": "Theater",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-optional": false,
                "icon-rotation-alignment": "auto",
                "icon-size": 0.2,
                "icon-anchor": "center",
                "icon-pitch-alignment": "auto"
            },
            "paint": {}
        },
        {
            "id": "symbol_poly-taxi",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 14.385139355434536,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "taxi"
            ],
            "layout": {
                "icon-image": "Taxi",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-size": 0.22,
                "visibility": "visible"
            }
        },
        {
            "id": "symbol_poly-swimming",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 13.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "sport",
                "swimming"
            ],
            "layout": {
                "icon-image": "Hallenbad",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "icon-size": 0.2,
                "visibility": "visible",
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "text-padding": 0,
                "text-allow-overlap": false,
                "text-ignore-placement": false,
                "text-offset": [
                    0,
                    2
                ]
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -11
                ]
            }
        },
        {
            "id": "symbol_poly-marina",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "symbol_poly",
            "minzoom": 14.385139355434536,
            "maxzoom": 24,
            "filter": [
                "==",
                "leisure",
                "marina"
            ],
            "layout": {
                "icon-image": "marina",
                "icon-allow-overlap": true,
                "icon-ignore-placement": false,
                "visibility": "visible"
            },
            "metadata": {
                "maputnik:comment": "im aktuellen kartengebiet keine daten"
            }
        },
        {
            "id": "ferry_text",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "ferry_text",
            "minzoom": 11.384638023421234,
            "maxzoom": 24,
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "symbol-placement": "line",
                "visibility": "visible",
                "text-allow-overlap": false,
                "text-ignore-placement": false,
                "icon-allow-overlap": true,
                "icon-ignore-placement": true
            },
            "paint": {
                "text-color": "#304f6f",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            }
        },
        {
            "id": "water_text-bay",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "water_text",
            "minzoom": 10.384608730578963,
            "maxzoom": 24,
            "filter": [
                "==",
                "natural",
                "bay"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "text-rotate": 0,
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#384e6c",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            }
        },
        {
            "id": "water_areas-txt-bay",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "water_areas",
            "minzoom": 4.35213367582087,
            "maxzoom": 24,
            "filter": [
                "==",
                "natural",
                "bay"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "text-rotate": 0,
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#384e6c",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            }
        },
        {
            "id": "water_text-lake",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "water_text",
            "minzoom": 7.298049766619153,
            "maxzoom": 10.384608730578963,
            "filter": [
                "any",
                [
                    "==",
                    "natural",
                    "water"
                ],
                [
                    "==",
                    "landuse",
                    "reservoir"
                ],
                [
                    "==",
                    "landuse",
                    "basin"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": {
                    "stops": [
                        [
                            7.298049766619153,
                            12
                        ],
                        [
                            12.384701950567761,
                            12
                        ],
                        [
                            22,
                            14
                        ]
                    ]
                },
                "text-anchor": "center",
                "text-rotate": 0,
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#37617e",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            }
        },
        {
            "id": "water_lines-txt",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "water_lines",
            "minzoom": 12.384701950567761,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "waterway",
                    "weir"
                ],
                [
                    "==",
                    "waterway",
                    "river"
                ],
                [
                    "==",
                    "waterway",
                    "canal"
                ],
                [
                    "==",
                    "waterway",
                    "derelict_canal"
                ],
                [
                    "==",
                    "waterway",
                    "stream"
                ],
                [
                    "==",
                    "waterway",
                    "drain"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": {
                    "stops": [
                        [
                            13.384840532309038,
                            12
                        ],
                        [
                            22,
                            14
                        ]
                    ]
                },
                "symbol-placement": "line",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#4562a2",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            }
        },
        {
            "id": "water_areas-txt-lake",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "water_areas",
            "minzoom": 8.298051830019984,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "natural",
                    "water"
                ],
                [
                    "==",
                    "landuse",
                    "reservoir"
                ],
                [
                    "==",
                    "landuse",
                    "basin"
                ],
                [
                    "==",
                    "name",
                    ""
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": {
                    "stops": [
                        [
                            10.384608730578963,
                            12
                        ],
                        [
                            12.384701950567761,
                            12
                        ],
                        [
                            22,
                            14
                        ]
                    ]
                },
                "text-anchor": "center",
                "text-rotate": 0,
                "visibility": "visible",
                "symbol-placement": "point",
                "text-ignore-placement": false,
                "text-allow-overlap": false,
                "text-rotation-alignment": "auto",
                "text-pitch-alignment": "auto",
                "text-transform": "none",
                "text-optional": false,
                "text-keep-upright": true
            },
            "paint": {
                "text-color": "#4562a2",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            }
        },
        {
            "id": "landmark_poly_text_lowzoom-nature",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text_lowzoom",
            "minzoom": 7.298049766619153,
            "maxzoom": 13,
            "filter": [
                "all",
                [
                    "any",
                    [
                        "==",
                        "leisure",
                        "park"
                    ],
                    [
                        "==",
                        "leisure",
                        "playground"
                    ],
                    [
                        "==",
                        "landuse",
                        "recreation_ground"
                    ],
                    [
                        "==",
                        "leisure",
                        "nature_reserve"
                    ],
                    [
                        "==",
                        "landuse",
                        "forest"
                    ]
                ],
                [
                    ">=",
                    "way_area",
                    1000000
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "visibility": "visible",
                "text-allow-overlap": false
            },
            "paint": {
                "text-color": {
                    "property": "way_area",
                    "type": "interval",
                    "stops": [
                        [
                            {
                                "zoom": 9.38459541712555,
                                "value": 25000000
                            },
                            "#006f53"
                        ],
                        [
                            {
                                "zoom": 13.384840532309038,
                                "value": 1000000
                            },
                            "#305943"
                        ]
                    ],
                    "default": "#006f53"
                },
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmarks nature lowzoom"
            }
        },
        {
            "id": "landmark_poly_text_lowzoom-education",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text_lowzoom",
            "minzoom": 12.384638023421234,
            "maxzoom": 13,
            "filter": [
                "all",
                [
                    "any",
                    [
                        "==",
                        "amenity",
                        "university"
                    ],
                    [
                        "==",
                        "amenity",
                        "college"
                    ]
                ],
                [
                    ">=",
                    "way_area",
                    500000
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmarks bildung\nlowzoom"
            }
        },
        {
            "id": "landmark_poly_text_lowzoom-military",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text_lowzoom",
            "minzoom": 11.384638023421234,
            "maxzoom": 13,
            "filter": [
                "all",
                [
                    "==",
                    "landuse",
                    "military"
                ],
                [
                    ">=",
                    "way_area",
                    500000
                ],
                [
                    "!=",
                    "amenity",
                    "university"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmarks military\nlowzoom"
            }
        },
        {
            "id": "landmark_poly_text_lowzoom-airport",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text_lowzoom",
            "minzoom": 9.38459541712555,
            "maxzoom": 13,
            "filter": [
                "any",
                [
                    "==",
                    "aeroway",
                    "apron"
                ],
                [
                    "==",
                    "aeroway",
                    "aerodrome"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-transform": "uppercase",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmarks flughafen\nlowzoom"
            }
        },
        {
            "id": "landmark_poly_text-nature",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 13,
            "maxzoom": 24,
            "filter": [
                "all",
                [
                    "any",
                    [
                        "==",
                        [
                            "get",
                            "leisure"
                        ],
                        "park"
                    ],
                    [
                        "==",
                        [
                            "get",
                            "leisure"
                        ],
                        "playground"
                    ],
                    [
                        "==",
                        [
                            "get",
                            "landuse"
                        ],
                        "recreation_ground"
                    ],
                    [
                        "==",
                        [
                            "get",
                            "leisure"
                        ],
                        "nature_reserve"
                    ],
                    [
                        "==",
                        [
                            "get",
                            "landuse"
                        ],
                        "forest"
                    ]
                ],
                [
                    "any",
                    [
                        ">=",
                        [
                            "number",
                            [
                                "get",
                                "way_area"
                            ]
                        ],
                        20000
                    ],
                    [
                        ">=",
                        [
                            "zoom"
                        ],
                        14
                    ]
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "visibility": "visible",
                "text-allow-overlap": false,
                "symbol-spacing": 250
            },
            "paint": {
                "text-color": "#305943",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmarks nature highzoom"
            }
        },
        {
            "id": "landmark_poly_text-military",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 14,
            "maxzoom": 15.108002780993148,
            "filter": [
                "all",
                [
                    "==",
                    "landuse",
                    "military"
                ],
                [
                    ">=",
                    "way_area",
                    100000
                ],
                [
                    "!=",
                    "amenity",
                    "university"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmarks military highzoom"
            }
        },
        {
            "id": "landmark_poly_text-education",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 14,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "amenity",
                    "university"
                ],
                [
                    "==",
                    "amenity",
                    "college"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmarks bildung\nhighzoom"
            }
        },
        {
            "id": "landmark_poly_text-golf",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 14.384840532309038,
            "maxzoom": 24,
            "filter": [
                "==",
                "leisure",
                "golf_course"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": {
                    "stops": [
                        [
                            14.385139355434536,
                            12
                        ],
                        [
                            15.108002780993148,
                            12
                        ]
                    ]
                },
                "text-anchor": "center",
                "visibility": "visible",
                "text-allow-overlap": false
            },
            "paint": {
                "text-color": "#006f53",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    35,
                    -15
                ]
            },
            "metadata": {
                "maputnik:comment": "landmark golf"
            }
        },
        {
            "id": "landmark_poly_text-sport-zoo",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 13.384840532309038,
            "maxzoom": 24,
            "filter": [
                "all",
                [
                    "any",
                    [
                        "==",
                        [
                            "get",
                            "leisure"
                        ],
                        "stadium"
                    ],
                    [
                        "==",
                        [
                            "get",
                            "leisure"
                        ],
                        "sports_centre"
                    ],
                    [
                        "==",
                        [
                            "get",
                            "tourism"
                        ],
                        "zoo"
                    ]
                ],
                [
                    "any",
                    [
                        ">=",
                        [
                            "number",
                            [
                                "get",
                                "way_area"
                            ]
                        ],
                        10000
                    ],
                    [
                        ">=",
                        [
                            "zoom"
                        ],
                        15
                    ]
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "visibility": "visible",
                "symbol-spacing": 250
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmark sport zoo"
            }
        },
        {
            "id": "landmark_poly_text-retail",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 15.108002780993148,
            "maxzoom": 24,
            "filter": [
                "==",
                "landuse",
                "retail"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmark retail"
            }
        },
        {
            "id": "landmark_poly_text-police",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 15.384840532309038,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "amenity",
                    "police"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "visibility": "visible",
                "text-padding": 2,
                "text-allow-overlap": false,
                "text-ignore-placement": false,
                "text-offset": [
                    0,
                    2
                ]
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -11
                ]
            }
        },
        {
            "id": "landmark_poly_text-theatre",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 14.385139355434536,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "amenity",
                    "theatre"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "visibility": "visible",
                "text-padding": 2,
                "text-allow-overlap": false,
                "text-ignore-placement": false,
                "text-offset": [
                    0,
                    2
                ]
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -11
                ]
            }
        },
        {
            "id": "landmark_poly_text-religous",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 15.385139355434536,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "amenity",
                    "place_of_worship"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "visibility": "visible",
                "text-padding": 2,
                "text-allow-overlap": false,
                "text-ignore-placement": false,
                "text-offset": [
                    0,
                    2
                ]
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -11
                ]
            },
            "metadata": {
                "maputnik:comment": "landmark religious"
            }
        },
        {
            "id": "landmark_poly_text-hospital",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 15.385139355434536,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "hospital"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "top",
                "visibility": "visible",
                "text-offset": [
                    0,
                    2
                ],
                "text-allow-overlap": false
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -11
                ]
            },
            "metadata": {
                "maputnik:comment": "landmark hospital"
            }
        },
        {
            "id": "landmark_poly_text-airport",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_poly_text",
            "minzoom": 13,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "==",
                    "aeroway",
                    "apron"
                ],
                [
                    "==",
                    "aeroway",
                    "aerodrome"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-transform": "uppercase",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": {
                    "stops": [
                        [
                            14.385139355434536,
                            12
                        ],
                        [
                            15.108002780993148,
                            12
                        ]
                    ]
                },
                "text-anchor": "center",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmark flughafen highzoom"
            }
        },
        {
            "id": "landmark_point_text-island",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "lxymbol",
            "minzoom": 12.384701950567761,
            "maxzoom": 24,
            "filter": [
                "==",
                "place",
                "island"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 10,
                "text-anchor": "center",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#000000",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "landmark point island"
            }
        },
        {
            "id": "landmark_point_text-ferryterminal",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "landmark_point_text",
            "minzoom": 15.108002780993148,
            "maxzoom": 24,
            "filter": [
                "==",
                "amenity",
                "ferry_terminal"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Light Italic"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#664f3d",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)",
                "text-translate": [
                    0,
                    -8
                ]
            },
            "metadata": {
                "maputnik:comment": "landmark point ferry"
            }
        },
        {
            "id": "street_text-name",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "street_text",
            "minzoom": 10,
            "maxzoom": 24,
            "filter": [
                "any",
                [
                    "all",
                    [
                        "==",
                        [
                            "get",
                            "highway"
                        ],
                        "motorway"
                    ],
                    [
                        ">=",
                        [
                            "zoom"
                        ],
                        10
                    ]
                ],
                [
                    "all",
                    [
                        "any",
                        [
                            "==",
                            [
                                "get",
                                "highway"
                            ],
                            "trunk"
                        ],
                        [
                            "==",
                            [
                                "get",
                                "highway"
                            ],
                            "trunk"
                        ],
                        [
                            "==",
                            [
                                "get",
                                "highway"
                            ],
                            "primary"
                        ],
                        [
                            "==",
                            [
                                "get",
                                "highway"
                            ],
                            "secondary"
                        ],
                        [
                            "==",
                            [
                                "get",
                                "highway"
                            ],
                            "proposed"
                        ],
                        [
                            "==",
                            [
                                "get",
                                "highway"
                            ],
                            "construction"
                        ]
                    ],
                    [
                        ">=",
                        [
                            "zoom"
                        ],
                        13
                    ]
                ],
                [
                    ">=",
                    [
                        "zoom"
                    ],
                    13.5
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Regular"
                ],
                "text-size": [
                    "step",
                    [
                        "zoom"
                    ],
                    [
                        "case",
                        [
                            "any",
                            [
                                "==",
                                [
                                    "get",
                                    "highway"
                                ],
                                "motorway"
                            ],
                            [
                                "==",
                                [
                                    "get",
                                    "highway"
                                ],
                                "trunk"
                            ],
                            [
                                "==",
                                [
                                    "get",
                                    "highway"
                                ],
                                "primary"
                            ],
                            [
                                "==",
                                [
                                    "get",
                                    "highway"
                                ],
                                "secondary"
                            ],
                            [
                                "==",
                                [
                                    "get",
                                    "highway"
                                ],
                                "proposed"
                            ],
                            [
                                "==",
                                [
                                    "get",
                                    "highway"
                                ],
                                "construction"
                            ]
                        ],
                        14,
                        12
                    ],
                    16,
                    14
                ],
                "symbol-placement": "line",
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#525252",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "all"
            }
        },
        {
            "id": "placename_s-locality",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "placename_small",
            "minzoom": 14.385139355434536,
            "maxzoom": 24,
            "filter": [
                "==",
                "place",
                "locality"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Medium"
                ],
                "text-size": 12,
                "text-anchor": "center",
                "text-rotate": 0,
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#787878",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            }
        },
        {
            "id": "placename_s-hamlet",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "placename_small",
            "minzoom": 11.384638023421234,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "place",
                    "hamlet"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Medium"
                ],
                "text-size": {
                    "stops": [
                        [
                            15.108002780993148,
                            12
                        ],
                        [
                            15.10800278099315,
                            14
                        ],
                        [
                            16.108388162092307,
                            18
                        ]
                    ]
                },
                "text-anchor": "center",
                "text-rotate": 0,
                "visibility": "visible"
            },
            "paint": {
                "text-color": "#787878",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            }
        },
        {
            "id": "placename_s-suburb",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "placename_small",
            "minzoom": 10.8,
            "maxzoom": 16,
            "filter": [
                "==",
                "place",
                "suburb"
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Medium"
                ],
                "text-size": {
                    "stops": [
                        [
                            12.384701950567761,
                            14
                        ],
                        [
                            14.385139355434536,
                            14
                        ],
                        [
                            15.10800278099315,
                            18
                        ],
                        [
                            16.108388162092307,
                            20
                        ]
                    ]
                },
                "text-anchor": "center",
                "visibility": "visible",
                "text-transform": "uppercase",
                "text-allow-overlap": false
            },
            "paint": {
                "text-color": "#3f4873",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.5)"
            },
            "metadata": {
                "maputnik:comment": "stadtteil"
            }
        },
        {
            "id": "placename_s-village",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "placename_small",
            "minzoom": 9.38459541712555,
            "maxzoom": 16.108388162092307,
            "filter": [
                "any",
                [
                    "==",
                    "place",
                    "village"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Medium"
                ],
                "text-size": {
                    "stops": [
                        [
                            12.384701950567761,
                            14
                        ],
                        [
                            15.10800278099315,
                            15
                        ],
                        [
                            16.108388162092307,
                            16
                        ]
                    ]
                },
                "text-anchor": "center",
                "text-rotate": 0,
                "visibility": "visible",
                "text-allow-overlap": {
                    "stops": [
                        [
                            12.384701950567761,
                            false
                        ],
                        [
                            12.384701950567763,
                            true
                        ]
                    ]
                }
            },
            "paint": {
                "text-color": "#525252",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.9)"
            }
        },
        {
            "id": "placename_m-town-sym",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "placename_medium",
            "minzoom": 6.298048863890273,
            "maxzoom": 14.385139355434536,
            "filter": [
                "any",
                [
                    "==",
                    "place",
                    "town"
                ],
                [
                    "==",
                    "place",
                    "large_town"
                ]
            ],
            "layout": {
                "icon-image": "small-city",
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "visibility": "visible"
            }
        },
        {
            "id": "placename_m-town-text",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "placename_medium",
            "minzoom": 6.298048863890273,
            "maxzoom": 14.385139355434536,
            "filter": [
                "any",
                [
                    "==",
                    "place",
                    "town"
                ],
                [
                    "==",
                    "place",
                    "large_town"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Medium"
                ],
                "text-size": {
                    "stops": [
                        [
                            7.298049766619153,
                            12
                        ],
                        [
                            9.38459541712555,
                            14
                        ],
                        [
                            14.385139355434536,
                            16
                        ]
                    ]
                },
                "text-anchor": "center",
                "visibility": "visible",
                "text-allow-overlap": false
            },
            "paint": {
                "text-color": "#525252",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.9)",
                "text-translate": [
                    0,
                    -11
                ]
            }
        },
        {
            "id": "placename_m-city-sym",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "placename_medium",
            "minzoom": 4.35213367582087,
            "maxzoom": 10.384608730578963,
            "filter": [
                "any",
                [
                    "==",
                    "place",
                    "city"
                ],
                [
                    "==",
                    "place",
                    "metropolis"
                ]
            ],
            "layout": {
                "icon-image": "small-city",
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "visibility": "visible",
                "icon-size": 1.3
            }
        },
        {
            "id": "placename_m-city-text",
            "type": "symbol",
            "source": "osm i-system",
            "source-layer": "placename_medium",
            "minzoom": 4.35213367582087,
            "maxzoom": 14.385139355434536,
            "filter": [
                "any",
                [
                    "==",
                    "place",
                    "city"
                ],
                [
                    "==",
                    "place",
                    "metropolis"
                ]
            ],
            "layout": {
                "text-field": "{name}",
                "text-pitch-alignment": "viewport",
                "text-font": [
                    "Vialog Medium"
                ],
                "text-size": {
                    "stops": [
                        [
                            6.298048863890273,
                            14
                        ],
                        [
                            8.298051830019984,
                            16
                        ],
                        [
                            12.384701950567761,
                            16
                        ],
                        [
                            14.385139355434536,
                            18
                        ]
                    ]
                },
                "text-anchor": "center",
                "visibility": "visible",
                "text-allow-overlap": true,
                "text-transform": "uppercase"
            },
            "paint": {
                "text-color": "#525252",
                "text-halo-width": 2,
                "text-halo-color": "rgba(255,255,255,0.9)",
                "text-translate": [
                    0,
                    -12
                ]
            }
        }
    ]
};
